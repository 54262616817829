import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: '../assets/icon/home.svg'
    },
    {
      title: 'Journey',
      url: '/journey',
      icon: '../assets/icon/journey.svg'
    },
    {
      title: 'Place',
      url: '/home',
      icon: '../assets/icon/place.svg'
    },
    {
      title: 'Event',
      url: '/home',
      icon: '../assets/icon/event.svg'
    },
    {
      title: 'Stamp',
      url: '/home',
      icon: '../assets/icon/stamp.svg'
    },
    {
      title: 'Favorite',
      url: '/home',
      icon: '../assets/icon/favorite.svg'
    },
    {
      title: 'Promotion',
      url: '/home',
      icon: '../assets/icon/promotion.svg'
    },
    {
      title: 'Language',
      url: '/language',
      icon: '../assets/icon/language.svg'
    },
    {
      title: 'About',
      url: '/home',
      icon: '../assets/icon/about.svg'
    }
  ];
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
